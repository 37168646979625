@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html {
  overflow-x: hidden;
}

.form-group .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  vertical-align: top;
}

.select2-dropdown {
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
}

.ui-sortable-handle {
  cursor: move;
}
